
.multiple-choice {
    margin: 0.5em;
    padding: 2em;
    background-color: lightgray;
    border-radius: 1em;
}

.multiple-choice .frage {
    margin-bottom: 1em;
}

.multiple-choice .gratulation {
    margin-top: 0.3em;
}

.multiple-choice .erklärung {
    margin-top: 1em;
    margin-bottom: 0.3em;
}

.multiple-choice .erklärung>div{
    display: inline-block;
    vertical-align: top;
}

.multiple-choice .antwort label{
    display: initial;
    padding-left: 0.5em;
}

.multiple-choice .antwort.richtig label {
    text-decoration: limegreen underline 3px;
}

.multiple-choice .antwort.falsch label {
    text-decoration: red wavy underline;
}

.multiple-choice .löse-auf {
    margin-top: 1em;
}

.multiple-choice .zeige-erklärung {
    margin-top: 1em;
}

.multiple-choice .gratulation {
    font-weight: bold;
}

.multiple-choice .weiche-anzeige {
    overflow: scroll;
}

.multiple-choice .weiche-anzeige.versteckt {
    max-height: 0;
    opacity: 0;
    transition: all 1s 0.5s;
}

.multiple-choice .weiche-anzeige.sichtbar {
    opacity: 1;
    transition: all 1s ease-in-out;
}